import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubjectList = ({ subjects, onSelectSubject }) => {
    console.log("subjects from subjects: ", subjects); // Logging subjects to verify data

    // Function to get a random gray or white shade
    const getRandomGrayShade = () => {
        const shades = [

            'bg-gray-200 hover:bg-gray-400',


        ];
        return shades[Math.floor(Math.random() * shades.length)];
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-black via-gray-600 to-white">
            <div className="bg-white p-12 rounded-3xl shadow-2xl max-w-lg w-full text-center  transform transition-all duration-500 hover:scale-105 hover:shadow-3xl">
                <h1 className="text-4xl font-extrabold mb-8 text-gray-800 tracking-wider">Your Subjects</h1>
                {subjects && subjects.length > 0 ? (
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 animate-fade-in">
                        {subjects.map((subject, index) => (
                            <li
                                key={index}
                                className={`p-4 ${getRandomGrayShade()} transition-all duration-500 border-2 border-gray-300 rounded-lg shadow-lg flex items-center justify-center text-lg font-semibold text-gray-700 cursor-pointer transform hover:scale-105 hover:border-gray-500 hover:shadow-xl`}
                                onClick={() => onSelectSubject(subject)}
                                tabIndex={0} // Ensure the element is focusable
                            >
                                {subject}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-lg text-gray-500 animate-pulse">No subjects found.</p>
                )}
            </div>
        </div>
    );
};

export default SubjectList;
