import { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios';
import { API_URL } from '../config.js';
const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textPaused, setTextPaused] = useState(false);  // New state for text pause control

  const chat = async (message) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/generateresponse/`, { //this (prompt llm)
        question: message
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync
        }))
      ]);

    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const studentchat = async (profileData, selectedSubject, selectedChapter, selectedSubtopics) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/studentsresponse/`, { //this api (promt llm for 2 qns) (RAG llm for class)
        profileData,
        selectedSubject,
        selectedChapter,
        selectedSubtopics,
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync,
          answer: item.correctAnswer,
          isDirectResponse: item.isDirectResponse,
        }))
      ]);
    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const studentchatui = async (profileData, selectedSubject, selectedChapter, selectedSubtopics,text) => {
    setLoading(true);
    
    try {
      const res = await axios.post(`${API_URL}/studentsresponse/`, {  //(llm)
        profileData,
        selectedSubject,
        selectedChapter,
        selectedSubtopics,
        question: text
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync,
          answer: item.correctAnswer,
          isDirectResponse: item.isDirectResponse,
          question: item.question
        }))
      ]);
    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const studentevaluation = async (cleanedAnswer,question,feedback,text) => {
    setLoading(true);
    console.log("Making API request:", "Answer :",cleanedAnswer, "Question",question);  // Debugging line

    try {
      const res = await axios.post(`${API_URL}/studentsresponseevaluation/`, {  
        answer: cleanedAnswer,
        question: question,
        feedback: feedback,
        text: text
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync,
          answer: item.correctAnswer,
          isDirectResponse: item.isDirectResponse,
          question: item.question
        }))
      ]);
    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const studentevaluationmalayalam = async (cleanedAnswer,question,feedback,text) => {
    setLoading(true);
    console.log("Making API request:", "Answer :",cleanedAnswer, "Question",question);  // Debugging line

    try {
      const res = await axios.post(`${API_URL}/studentsresponseevaluationmalayalam/`, {  
        answer: cleanedAnswer,
        question: question,
        feedback: feedback,
        text: text
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync,
          answer: item.correctAnswer,
          isDirectResponse: item.isDirectResponse,
          question: item.question
        }))
      ]);
    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendPercentageToBackend = async (profileData, percentage) => {
    try {
      const res = await axios.post(`${API_URL}/send-percentage/`, {
        profileData,
        percentage,
      });
  
      if (res.data.message) {
        console.log("Successfully sent percentage and profile data to backend.");
      }
    } catch (error) {
      console.error("Failed to send percentage to backend:", error);
    }
  };


  const photohandle = async (photoData) => {
    setLoading(true);
    console.log("Making API request:", "Answer :",photoData);  // Debugging line

    try {
      const res = await axios.post(`${API_URL}/photohandle/`, {  
        image: photoData,
      });

      const responseArray = res.data.response;
      setMessages((messages) => [
        ...messages,
        ...responseArray.map((item) => ({
          text: item.text,
          facialExpression: item.facialExpression,
          animation: item.animation,
          audio: item.audio,
          lipsync: item.lipsync,
        }))
      ]);
    } catch (error) {
      console.error("Failed to fetch response from Django backend:", error);
    } finally {
      setLoading(false);
    }
  };

  const onMessagePlayed = () => {
    setMessages((messages) => messages.slice(1));
  };

  useEffect(() => {
    if (messages.length > 0) {
      const currentMessage = messages[0];
      setMessage(currentMessage);
  
      // Check if the message contains "definition", "intro_question_2", or "conclusion"
      if (currentMessage.text && (currentMessage.text.commondifference || currentMessage.text.ensureqns)) {
        setTextPaused(true);  // Pause text rendering for 10 seconds for definition or intro question
        setTimeout(() => setTextPaused(false), 15000); 
      } 
      // else if (currentMessage.text && currentMessage.text.conclusion) {
      //   setTextPaused(true);  // Pause text rendering for 5 seconds for conclusion
      //   setTimeout(() => setTextPaused(false), 5000); 
      // } 
      else {
        setTextPaused(false);  // Default to unpaused if no special conditions are met
      }
    } else {
      setMessage(null);
    }
  }, [messages]);
  
  return (
    <ChatContext.Provider
      value={{
        chat,
        studentchat,
        studentchatui,
        studentevaluation,
        studentevaluationmalayalam,
        photohandle,
        sendPercentageToBackend,
        message,
        onMessagePlayed,
        loading,
        textPaused,  // Expose textPaused to components
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
