import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';
import "../globals.css";

const WorkBoard = () => {
  const { message } = useChat();
  const [messages, setMessages] = useState([]);
  const [displayedMessage, setDisplayedMessage] = useState(''); // For typewriter animation
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null); // Index of the message being animated
  const containerRef = useRef(null);

  // Handle new message
  useEffect(() => {
    if (message && message.text.works) {
      const newMessage = {
        content: message.text.works || 'No message provided',
      };

      // Add the new message to the state
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        setCurrentMessageIndex(updatedMessages.length - 1); // Set the index for typewriter animation
        return updatedMessages;
      });
    }
  }, [message]);

  // Typewriter animation
  useEffect(() => {
    if (
      currentMessageIndex !== null &&
      messages[currentMessageIndex] &&
      typeof messages[currentMessageIndex].content === 'string' // Ensure content is a string
    ) {
      const currentContent = messages[currentMessageIndex].content;
      let index = 0;

      const intervalId = setInterval(() => {
        if (index < currentContent.length) {
          setDisplayedMessage((prev) => currentContent.slice(0, index + 1));
          index++;
        } else {
          clearInterval(intervalId);
          setDisplayedMessage(''); // Clear animation text
          setCurrentMessageIndex(null); // Reset animation index
        }

        // Scroll to the bottom during the animation
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 40); // Typing speed

      return () => clearInterval(intervalId);
    }
  }, [currentMessageIndex, messages]);

  // Scroll to bottom of container when a new message is added
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]); // Trigger this when messages change

  return (
    <Html position={[1.63, -0.39, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[500px] h-[431px] p-6 rounded-2xl text-white overflow-hidden"
        style={{
          border: '4px solid #555', // Soft border for a refined look
        }}
      >
        {/* Work Board Header */}
        <div className="flex justify-center mb-4">
          <h3 className="text-4xl font-bold text-gray-700 underline mb-2">
            Work Board
          </h3>
        </div>

        {/* Messages and Animation */}
        <div
          className="flex flex-col space-y-4 overflow-y-auto h-[400px] scrollbar-thin scrollbar-thumb scrollbar-track"
          ref={containerRef}
          style={{
            maxHeight: 'calc(100% - 80px)', // Ensure proper height for scrolling
            overflowY: 'auto', // Enable vertical scroll
            paddingBottom: '20px', // Extra padding to ensure scroll space
            paddingRight: '10px', // Add some space from the scrollbar
          }}
        >
          {messages.map((msg, index) => (
            <div
              className="bg-gray-800 p-4 mb-4 rounded-lg break-words"
              key={index} // Key for each message
            >
              <h4 className="text-white font-semibold text-2xl">
                {index === currentMessageIndex
                  ? displayedMessage // Typewriter animation
                  : typeof msg.content === 'string' // Render string content
                    ? msg.content.split('\n').map((line, idx) => (
                      <span
                        key={idx}
                        className={`block mb-2 ${/example|for example/i.test(line) // Regex to match "example" or "for example"
                            ? "text-yellow-500 font-semibold" // Highlight both "example" and "for example"
                            : "text-white"
                          }`}
                      >
                        {line}
                      </span>
                    ))
                    : null}
              </h4>
            </div>
          ))}

        </div>
      </div>
    </Html>
  );
};

export default WorkBoard;
