import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import "../globals.css";

const IntractionBoard = ({
  profileData,
  selectedSubject,
  selectedChapter,
  selectedSubtopics,
  text,
  question,
}) => {
  const { message, loading } = useChat();
  const [messages, setMessages] = useState([]);
  const [displayedMessage, setDisplayedMessage] = useState(''); // For typewriter animation
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null); // Index of the message being animated
  const containerRef = useRef(null);

  useEffect(() => {
    if (message && message.text && message.text.intraction) {
      // Extract question and interaction
      const newMessage = {
        questionbknd: message.question || 'No question provided',
        intraction: message.text.intraction || 'No response provided',
      };

      // Add the new message to the state
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setCurrentMessageIndex(messages.length); // Set the index for typewriter animation
    }
  }, [message]);

  useEffect(() => {
    if (currentMessageIndex !== null && messages[currentMessageIndex]) {
      const currentMessage = messages[currentMessageIndex].intraction;
      let index = 0;

      const intervalId = setInterval(() => {
        if (index < currentMessage.length) {
          setDisplayedMessage(currentMessage.slice(0, index + 1));
          index++;
        } else {
          clearInterval(intervalId);
          setDisplayedMessage(''); // Clear the typewriter text
          setCurrentMessageIndex(null); // Reset the current message index
        }
      }, 50); // Typing speed in milliseconds

      return () => clearInterval(intervalId);
    }
  }, [currentMessageIndex, messages]);

  useEffect(() => {
    if (containerRef.current) {
      // Ensure that the scroll position is updated whenever messages or content change
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages, displayedMessage, question]); // Trigger scrolling when these change

  return (
    <Html position={[1.63, 0.16, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[500px] h-[431px] p-5 bg-gray/70 rounded-2xl text-white overflow-hidden"
        style={{
          border: '4px solid #555', // Medium gray border color
        }}
      >
        {/* Interaction Board Header */}
        <div className="flex justify-center mb-4">
          <h3 className="text-4xl font-bold text-gray-700 underline mb-2">
            Discussion Board
          </h3>
        </div>

        {/* Show Messages and Loading Spinner */}
        <div
          className="flex flex-col space-y-4 overflow-y-auto h-[400px] scrollbar-thin scrollbar-thumb scrollbar-track" // Ensure enough height and scrollable space
          ref={containerRef}
          style={{
            maxHeight: 'calc(100% - 60px)', // Allow more space if needed (adjust this for better behavior)
            overflowY: 'auto', // Ensure vertical scrolling
            paddingRight: '10px', // Add some space from the scrollbar

          }}
        >
          {/* Display Previous Messages */}
          {messages.map((msg, index) => (
            <div key={index} className="w-full mb-4">
              {/* Question Section */}
              <div className="flex justify-start mb-2">
                <div className="text-white bg-gray-600 p-2 rounded-lg max-w-[75%] break-words">
                  <h3 className="text-2xl font-semibold">{msg.questionbknd}</h3>
                </div>
              </div>

              {/* Answer Section */}
              <div className="flex justify-end">
                <div className="text-white bg-gray-800 p-2 rounded-lg max-w-[85%] break-words">
                  <h4 className="font-semibold text-2xl">
                    {index === currentMessageIndex
                      ? displayedMessage // Typewriter animation for the current message
                      : msg.intraction.split('\n').map((line, idx) => (
                        <span
                          key={idx}
                          className={`block mb-1 ${/example|for example/i.test(line) // Regex to match both "example" and "for example"
                              ? "text-yellow-500 font-bold" // Apply yellow color and bold if the line contains "example" or "for example"
                              : "text-white" // Keep other lines in white
                            }`}
                        >
                          {line}
                        </span>
                      ))}
                  </h4>
                </div>
              </div>
            </div>
          ))}


          {/* Display Loading Spinner and Question (only when there is a question and loading is true) */}
          {question && loading && (
            <div className="mt-6 flex justify-start items-center space-x-4 flex-wrap">
              {/* Display Question while loading */}
              <h3 className="text-2xl text-gray-800 font-semibold">{question}</h3>

              {/* FontAwesome spinning icon */}
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="2x"
                className="text-indigo-500" // Spinner color
              />
            </div>
          )}
        </div>
      </div>
    </Html>
  );
};

export default IntractionBoard;