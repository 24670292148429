import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';

const MessageList = () => {
  const { message } = useChat();
  const [messages, setMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]); // Messages that have finished animating
  const [displayedMessage, setDisplayedMessage] = useState(''); // The message currently animating
  const containerRef = useRef(null);

  useEffect(() => {
    if (message && message.text) {
      setMessages((prevMessages) => [...prevMessages, message.text]);
    }
  }, [message]);

  useEffect(() => {
    if (messages.length > displayedMessages.length) {
      const currentMessage = messages[displayedMessages.length];
      let index = 0;

      // Reset displayedMessage at the start of displaying a new message
      setDisplayedMessage('');

      const intervalId = setInterval(() => {
        if (index < currentMessage.length) {
          setDisplayedMessage(currentMessage.slice(0, index + 1)); // Show text up to the current index
          index++;
        } else {
          clearInterval(intervalId);
          setDisplayedMessages((prev) => [...prev, currentMessage]); // Add completed message to displayedMessages
          setDisplayedMessage(''); // Reset displayedMessage for the next message
        }
      }, 50); // Typing speed

      return () => clearInterval(intervalId);
    }
  }, [messages, displayedMessages]);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [displayedMessages, displayedMessage]);

  return (
    <Html position={[0.3, -0.05, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[1288px] h-[676px] p-8 overflow-y-auto bg-transparent opacity-80"
        ref={containerRef}
      >
        {messages.length === 0 ? (
          <div className="h-full w-full grid place-content-center text-center">
            <h2 className="text-8xl font-bold text-white/90 italic">
              School of
              <br />
              wallPiller
            </h2>
            <h2 className="text-8xl font-bold font-jp text-red-600/90 italic">
              {/* वॉलपिलर हिंदी भाषा स्कूल */}
            </h2>
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            {displayedMessages.map((msg, index) => (
              <div key={index} className="text-center py-2">
                <h2 className="text-6xl font-bold text-white/90 italic">
                  {msg}
                </h2>
              </div>
            ))}
            {displayedMessage && (
              <div className="text-center py-2">
                <h2 className="text-6xl font-bold text-white/90 italic">
                  {displayedMessage}
                </h2>
              </div>
            )}
          </div>
        )}
      </div>
    </Html>
  );
};

export default MessageList;
