import { Environment,Gltf,OrbitControls} from "@react-three/drei";
import { Avatar } from "./Avarar";
import { degToRad } from "three/src/math/MathUtils";

export const Experience = () => {
  
    return (
        <> 
        <OrbitControls />
        <Environment preset="sunset"/>
        <Gltf src="/models/classroom.glb" position={[0.4, -1.7, -2]}/>
        <Avatar teacher={"Piller"} position={[-1.1, -1.7, -5]} 
        scale={1} rotation-y={degToRad(20)} />
      </>
    );
};