import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { API_URL } from '../config.js';

const SubtopicsList = ({ subject, chapter, profileData, onSubtopicClick }) => {
  const [subtopics, setSubtopics] = useState([]);
  const [error, setError] = useState(null);

  const fetchSubtopics = useCallback(debounce(async () => {
    try {
      const response = await axios.post(`${API_URL}/subtopics/`, {
        subject,
        chapter,
        class: profileData.class, // Send class info
        syllabus: profileData.syllabus,
        state: profileData.state,
        language: profileData.language,
      });
      console.log('subtopics from SubtopicsList:', response.data);
      setSubtopics(response.data.subtopics);
      setError(null); // Clear any previous error
    } catch (error) {
      console.error('Error fetching subtopics:', error);
      setError('Failed to fetch subtopics.');
    }
  }, 1000), [chapter, subject, profileData]); // 1-second debounce

  useEffect(() => {
    if (chapter) {
      fetchSubtopics();
    }
  }, [chapter, fetchSubtopics]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-black via-gray-600 to-white">
      <div className="bg-white p-12 rounded-3xl shadow-2xl max-w-lg w-full text-center  transform transition-all duration-500 hover:scale-105 hover:shadow-3xl">
        <h1 className="text-4xl font-extrabold mb-8 text-gray-800 tracking-wider">Subtopics for {chapter}</h1>
        {error ? (
          <p className="text-lg text-red-500">{error}</p>
        ) : (
          subtopics.length > 0 ? (
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 animate-fade-in">
              {subtopics.map((subtopic, index) => (
                <li
                  key={index}
                  className="p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-300 border border-gray-300 rounded-lg shadow-md text-lg font-semibold text-gray-700 cursor-pointer"
                  onClick={() => onSubtopicClick(subtopic)}  // Pass clicked subtopic
                >
                  {subtopic}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-lg text-gray-500">No subtopics found.</p>
          )
        )}
      </div>
    </div>
  );
};

export default SubtopicsList;
