import { useRef, useState, useEffect } from "react";
import { useChat } from "../hooks/useChat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faPaperPlane, faVolumeUp, faCamera, faUpload } from "@fortawesome/free-solid-svg-icons";

export const StudentUI = ({ hidden,profileData, selectedSubject, selectedChapter, selectedSubtopics, setQuestion, ...props }) => {
  const input = useRef();
  const { studentchatui,photohandle, chat, loading, message } = useChat();
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  // Initialize the speech recognition when the component mounts
  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech Recognition not supported in this browser");
      return;
    }

    const speechRecognition = new window.webkitSpeechRecognition();
    speechRecognition.continuous = false; // Stop after one phrase
    speechRecognition.interimResults = false; // Only final results

    speechRecognition.lang =
      profileData.language?.toLowerCase() === "malayalam" ? "ml-IN" : "en-US";

    speechRecognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      input.current.value = speechToText; // Set recognized text in the input field
      handleMessage(speechToText); // Send the recognized text to chat
    };

    speechRecognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    setRecognition(speechRecognition); // Store the recognition object
  }, [chat, profileData.language]); // Add profileData.language to the dependency array

  const startListening = () => {
    if (recognition) {
      setIsListening(true);
      recognition.start();
      recognition.onend = () => setIsListening(false); // Set listening to false when speech ends
    }
  };

  const handleMessage = (text) => {
    if (!loading && !message) {
      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text);
      input.current.value = ""; // Clear the input field after sending message
      setQuestion(text); // Pass the question to the parent component
    }
  };

  const sendMessage = () => {
    const text = input.current.value;
    if (text) {
      handleMessage(text); // Use the same handleMessage function for text input
    }
  };
  const openCameraModal = async () => {
    try {
      setPhotoModalOpen(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePhoto = () => {
    console.log("Capture button clicked");
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext("2d");

      // Ensure the canvas dimensions match the video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current frame of the video onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a Base64-encoded string
      const imageData = canvas.toDataURL("image/png");
      const base64Data = imageData.split(",")[1]; // Only the Base64 string

      console.log("Image captured: ", base64Data); // For debugging

      sendPhotoToBackend(base64Data) // Send the photo to the backend
        .then(() => {
          closeCameraModal(); // Automatically close the camera modal on success
        })
        .catch((error) => {
          console.error("Error sending photo:", error); // Log errors
        });
    }
  };

  const closeCameraModal = () => {
    setPhotoModalOpen(false);
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const sendPhotoToBackend = async (photoData) => {
    try {
      await photohandle(photoData);
      console.log("Photo successfully sent to the backend.");
    } catch (error) {
      console.error("Error sending photo to backend:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(",")[1]; // Get the base64 part
        await sendPhotoToBackend(base64Image);
      };
      reader.readAsDataURL(file);
  
      // Reset the file input so that the user can upload another photo
      fileInputRef.current.value = "";
    }
  };
  
  if (hidden) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-10 flex flex-col justify-end p-4 pointer-events-none">
  <div className="flex items-center justify-center gap-2 max-w-screen-sm w-full mx-auto pointer-events-auto">
    <input
      className="w-60 p-2 text-sm text-black placeholder:text-gray-500 placeholder:italic rounded-md border-2 border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ease-in-out"
      placeholder="Type a message or speak..."
      ref={input}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          sendMessage();
        }
      }}
    />
    <button
      disabled={loading || message}
      onClick={sendMessage}
      className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105"
    >
      <FontAwesomeIcon icon={faPaperPlane} className="text-lg" />
    </button>
    <button
      disabled={loading || message}
      onClick={startListening}
      className={`flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105 ${isListening ? "cursor-not-allowed opacity-50" : ""}`}
    >
      <FontAwesomeIcon icon={isListening ? faVolumeUp : faMicrophone} className="text-lg" />
    </button>
    <button
      onClick={openCameraModal}
      className="flex items-center justify-center bg-purple-500 hover:bg-purple-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105"
    >
      <FontAwesomeIcon icon={faCamera} className="text-lg" />
    </button>
    {/* New Upload Image Button */}
    <label
      htmlFor="upload-photo"
      className="flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105"
    >
      <FontAwesomeIcon icon={faUpload} className="text-lg" />
    </label>
    <input
      id="upload-photo"
      type="file"
      accept="image/*"
      ref={fileInputRef}
      onChange={handleFileChange}
      className="hidden"
    />
  </div>

  {/* Photo Capture Modal */}
  {photoModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-20 pointer-events-auto">
      <div className="relative">
        <video ref={videoRef} className="w-full max-w-md bg-black rounded-lg" />
        <div className="flex justify-between mt-2">
          <button
            onClick={capturePhoto}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Capture
          </button>
          <button
            onClick={closeCameraModal}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
      <canvas ref={canvasRef} className="hidden" />
    </div>
  )}
</div>

  );
};
