import { useRef, useState, useEffect } from "react";
import { useChat } from "../hooks/useChat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faPaperPlane, faVolumeUp} from "@fortawesome/free-solid-svg-icons";

export const UI = ({ hidden, ...props }) => {
  const input = useRef();
  const { chat, loading, message } = useChat();
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);

  // Initialize the speech recognition when the component mounts
  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech Recognition not supported in this browser");
      return;
    }
    const speechRecognition = new window.webkitSpeechRecognition();
    speechRecognition.continuous = false; // Stop after one phrase
    speechRecognition.interimResults = false; // Only final results
    speechRecognition.lang = "en-US"; // "ml-IN" for Malayalam , "hi-IN" Hindi, Set language

    speechRecognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      input.current.value = speechToText; // Set recognized text in the input field
      chat(speechToText); // Send the recognized text to chat
    };

    speechRecognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    setRecognition(speechRecognition); // Store the recognition object
  }, [chat]); // Add chat to the dependency array

  const startListening = () => {
    if (recognition) {
      setIsListening(true);
      recognition.start();
      recognition.onend = () => setIsListening(false); // Set listening to false when speech ends
    }
  };

  const sendMessage = () => {
    const text = input.current.value;
    if (!loading && !message) {
      chat(text);
      input.current.value = "";
    }
  };


  
  if (hidden) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-10 flex flex-col justify-end p-4 pointer-events-none">
      <div className="flex items-center justify-center gap-2 max-w-screen-sm w-full mx-auto pointer-events-auto">
        <input
          className="w-80 p-2 text-sm text-black placeholder:text-gray-500 placeholder:italic rounded-md border-2 border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ease-in-out"
          placeholder="Type a message or speak..."
          ref={input}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <button
          disabled={loading || message}
          onClick={sendMessage}
          className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105"
        >
          <FontAwesomeIcon icon={faPaperPlane} className="text-lg" />
        </button>
        <button
          disabled={loading || message}
          onClick={startListening}
          className={`flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-md transition-all duration-200 ease-in-out transform hover:scale-105 ${isListening ? "cursor-not-allowed opacity-50" : ""}`}
        >
          <FontAwesomeIcon icon={isListening ? faVolumeUp : faMicrophone} className="text-lg" />
        </button>

      </div>


    </div>

  );

};