import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { API_URL } from '../config.js';

const ChaptersList = ({ subject, classInfo, syllabus, state, language, onSelectChapter }) => {
  const [chapters, setChapters] = useState([]);
  const [error, setError] = useState(null);

  const fetchChapters = useCallback(debounce(async () => {
    try {
      const response = await axios.post(`${API_URL}/chapters/`, {
        subject,
        class: classInfo,
        syllabus,
        state,
        language
      });
      console.log("chapters from chapterjsx", response.data);
      setChapters(response.data.chapters);
      setError(null); // Clear any previous error
    } catch (error) {
      console.error('Error fetching chapters:', error);
      setError('Failed to fetch chapters.');
    }
  }, 1000), [subject, classInfo, syllabus, state, language]); // 1 second debounce

  useEffect(() => {
    if (subject) {
      fetchChapters();
    }
  }, [subject, fetchChapters]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-black via-gray-600 to-white">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-2xl w-full text-center backdrop-blur-sm">
        {/* Increased width with max-w-2xl */}
        <h1 className="text-4xl font-extrabold mb-6 text-gray-800 tracking-wide">Chapters for {subject}</h1>
        {error ? (
          <p className="text-lg text-red-500">{error}</p>
        ) : (
          chapters.length > 0 ? (
            <div className="overflow-y-auto max-h-[500px] space-y-4">
              {/* Increased height with max-h-[500px] */}
              <ul className="space-y-4">
                {chapters.map((chapter, index) => (
                  <li
                    key={index}
                    className={`p-4 items-center text-xl bg-gray-100 transition-colors duration-300 border-2 border-gray-300 rounded-lg shadow-md flex items-center justify-between text-lg font-semibold text-gray-700 cursor-pointer transform  hover:border-gray-800 hover:shadow-lg`}
                    onClick={() => onSelectChapter(chapter)}
                    tabIndex={0} // Ensure the element is focusable
                  >
                    <span>{chapter}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-lg text-gray-500">No chapters found.</p>
          )
        )}
      </div>
    </div>
  );
};

export default ChaptersList;
