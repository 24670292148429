import React, { useState, useEffect } from "react";
import { UserProfile } from "./components/UserProfile";
import { CameraControls, Loader } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Experience } from "./components/Experience";
import "./globals.css";
import { UI } from "./components/UI";
import { Leva } from "leva";
import MessageList from "./components/MessageList";
import { UserTypeSelection } from "./components/UserType";
import SubjectList from "./components/SubjectList";
import ChaptersList from "./components/ChaptersList";
import { useChat } from "./hooks/useChat";
import SubtopicsList from './components/SubtopicsList';
import StMessageList from "./components/StMessageList";
import StMessListTM from "./components/StMessListTM";
import StMessListTMMalayalam from "./components/StMessListTMMalayalam";
import {StudentUI} from "./components/StudentUI"; // Import StudentUI component
import IntractionBoard from "./components/IntractionBoard";
import WorkBoard from "./components/WorkBoard";

function App() {
  const [userType, setUserType] = useState(null);
  const [profileData, setProfileData] = useState(null);  // Holds profile data
  const [subjects, setSubjects] = useState(null);  // Holds subjects list
  const [selectedSubject, setSelectedSubject] = useState(null); // Holds the selected subject
  const [selectedChapter, setSelectedChapter] = useState(null); // Track selected chapter
  const [selectedSubtopics, setSelectedSubtopic] = useState(null); // Track selected subtopic
  const [subtopicsSent, setSubtopicsSent] = useState(false); // Flag for subtopics
  const [question, setQuestion] = useState(''); // State to hold the user's question

  const { studentchat } = useChat();  // Call useChat at the top level

  const handleProfileComplete = (data) => {
    setProfileData(data);
    setSubjects(data.subjects);
  };

  const handleUserTypeSelection = (type) => {
    setUserType(type);
  };

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
  };

  const handleSelectChapter = (chapter) => {
    setSelectedChapter(chapter);
  };

  const handleSelectSubtopic = (subtopic) => {
    setSelectedSubtopic(subtopic);
  };

  const switchToNonStudent = () => {
    setUserType('non-student');
  };



  // Trigger chat with the selected subtopic only if it hasn't been sent yet
  useEffect(() => {
    if (selectedSubtopics && !subtopicsSent) {
      studentchat(profileData, selectedSubject, selectedChapter, selectedSubtopics);
      setSubtopicsSent(true);
    }
  }, [selectedSubtopics, profileData, selectedSubject, selectedChapter, studentchat, subtopicsSent]);

  // 1. User selects type of user (student or non-student)
  if (!userType) {
    return <UserTypeSelection onUserTypeSelect={handleUserTypeSelection} />;
  }

  // 2. If the user is a student and hasn't completed their profile yet, show the profile form
  if (userType === "student" && !profileData) {
    return <UserProfile onComplete={handleProfileComplete} />;
  }

  // 3. After completing the profile, show the subjects list
  if (userType === "student" && profileData && !selectedSubject) {
    return <SubjectList subjects={subjects} onSelectSubject={handleSelectSubject} />;
  }

  // 4. Once a subject is selected, show the chapters list
  if (userType === "student" && profileData && selectedSubject && !selectedChapter) {
    return (
      <ChaptersList
        subject={selectedSubject}
        classInfo={profileData.class}
        syllabus={profileData.syllabus}
        state={profileData.state}
        language={profileData.language}
        onSelectChapter={handleSelectChapter}
      />
    );
  }

  // 5. After chapter is selected, show the subtopics list
  if (userType === 'student' && profileData && selectedSubject && selectedChapter && !selectedSubtopics) {
    return (
      <SubtopicsList
        subject={selectedSubject}
        chapter={selectedChapter}
        profileData={profileData}
        onSubtopicClick={handleSelectSubtopic}
      />
    );
  }

// 6. Once a subject and subtopic are selected, show the Experience and MessageList components
if (userType === 'student' && profileData && selectedSubject && selectedSubtopics) {
  return (
    <>
      <Loader />
      <Leva hidden />
      <Canvas camera={{ position: [0, 0, 0.0001] }} style={{ height: '100vh', width: '100vw' }}>
        <Experience />
        {profileData.language === "Malayalam" ? (
          <StMessListTMMalayalam
            profileData={profileData}  // Pass profileData
            selectedSubject={selectedSubject}  // Pass selectedSubject
            selectedChapter={selectedChapter}  // Pass selectedChapter
            selectedSubtopics={selectedSubtopics}  // Pass selectedSubtopics
            onSwitchToNonStudent={switchToNonStudent}
          />
        ) : (
          <StMessListTM
            profileData={profileData}  // Pass profileData
            selectedSubject={selectedSubject}  // Pass selectedSubject
            selectedChapter={selectedChapter}  // Pass selectedChapter
            selectedSubtopics={selectedSubtopics}  // Pass selectedSubtopics
            onSwitchToNonStudent={switchToNonStudent}
          />
        )}

        {/* Display IntractionBoard here */}
        <IntractionBoard 
          profileData={profileData}
          selectedSubject={selectedSubject}
          selectedChapter={selectedChapter}
          selectedSubtopics={selectedSubtopics}
          question={question} // Pass the question to IntractionBoard

        />
         {/* Display workBoard here */}
         <WorkBoard
          profileData={profileData}
          selectedSubject={selectedSubject}
          selectedChapter={selectedChapter}
          selectedSubtopics={selectedSubtopics}
          question={question} // Pass the question to workBoard
        />
        <CameraManager />
      </Canvas>
      <StudentUI
        profileData={profileData}
        selectedSubject={selectedSubject}
        selectedChapter={selectedChapter}
        selectedSubtopics={selectedSubtopics}
        setQuestion={setQuestion} // Pass the setQuestion function to StudentUI

      />
    </>
  );
}

  // 7. For non-students, render the non-student experience and messages
  return (
    <>
      <Loader />
      <Leva hidden />
      <Canvas camera={{ position: [0, 0, 0.0001] }} style={{ height: "100vh", width: "100vw" }}>
        {userType === "non-student" && <Experience />}
        {userType === "non-student" && <MessageList />}
        <CameraManager />
      </Canvas>
      {userType === "non-student" && <UI />}
    </>
  );
}

const CameraManager = () => {
  return (
    <CameraControls
      minZoom={1}
      maxZoom={8}
      polarRotateSpeed={-0.3}
      azimuthRotateSpeed={-0.3}
      mouseButtons={{
        left: 1,
        wheel: 16,
      }}
      touches={{
        one: 32,
        two: 512,
      }}
    />
  );
};

export default App;
