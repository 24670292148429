import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';  // Importing the spinning icon
import PdfDownloadButton from './PdfDownloadButton.jsx'

const questionsEnsure = [
  { id: 1, question: "ഒരു സമാന്തര ശ്രേണിയിലെ ആദ്യത്തെ നാല് പദങ്ങൾ എഴുതുക ?", correctAnswer: 14 },
  { id: 2, question: "സമാന്തര ശ്രേണി അല്ലാത്ത ഒരു ശ്രേണിയിലെ ആദ്യത്തെ നാല് പദങ്ങൾ എഴുതുക ?", correctAnswer: 30 },
];
const questions = [
  { id: 1, question: "2, 5, 8, 11...ഈ ശ്രേണിയിലെ 5-ാമത്തെ പദം കണ്ടെത്തുക ?", correctAnswer: 14 },
  { id: 2, question: "3, 6, 9, 12, ...ഈ ശ്രേണിയിലെ  10-ാമത്തെ പദം കണ്ടെത്തുക ?", correctAnswer: 30 },
  { id: 3, question: "ഒരു സമാന്തര ശ്രേണിയുടെ ആദ്യ പദം 7 ഉം പൊതുവായ വ്യത്യാസം 4 ഉം ആണെങ്കിൽ, ആറാമത്തെ പദം എന്താണ്?", correctAnswer: 27 },
  { id: 4, question: "ഈ ശ്രേണിയിലെ പൊതുവായ വ്യത്യാസം എന്താണ്: -3, 1, 5, 9, ...?", correctAnswer: 4 }
];
const questionsone = [
  { id: 1, question: "1, 4, 9, 16, 25, ... ഈ ശ്രേണിയിൽ അടുത്തടുത്ത നമ്പറുകളുടെ വ്യത്യാസം തുല്യമാണോ ? ", correctAnswer: 'No' },
  { id: 2, question: "ഇവിടെ 2, 3, 4... ഈ ലിസ്റ്റിലെ രണ്ട് അടുത്തടുത്ത പദങ്ങൾ തമ്മിലുള്ള വ്യത്യാസം ഒന്നുതന്നെയാണോ?", correctAnswer: 'Yes' }
]; const questionsbeforeeq = [
  { id: 1, question: "1, 4, 7, 10, 13 ... അടുത്ത പദം എന്താണ് ?  or ആറാമത്തെ പദം കണ്ടെത്തുക ?", correctAnswer: 16 },
  { id: 2, question: "1, 4, 7, 10, 13 ...പത്താമത്തെ പദം കണ്ടെത്തുക?", correctAnswer: 28 }
];


const StMessListTMMalayalam = ({ profileData, selectedSubject, selectedChapter, selectedSubtopics, text }) => {
  const { message, sendPercentageToBackend, studentevaluationmalayalam, textPaused, studentchatui, loading, studentevaluation } = useChat();
  const [messages, setMessages] = useState([]);
  const [doubtResponse, setDoubtResponse] = useState(null);
  const [displayedText, setDisplayedText] = useState({}); // Holds the currently animated text for each part
  const containerRef = useRef(null);

  const [feedback, setFeedback] = useState(Array(4).fill(""));
  const [showQuestions, setShowQuestions] = useState(false);
  const [showQuestionsbeforeEq, setshowQuestionsbeforeEq] = useState(false);
  const [showQuestionsEnsureQns, setshowQuestionsEnsureQns] = useState(false);

  const [inputAnswers, setInputAnswers] = useState(Array(4).fill("")); // State to track input values
  const totalQuestions = questions.length; // Total number of questions (4 in this case)
  const [showQuestionsOne1, setShowQuestionsOne1] = useState(false);  // State to track button click
  const [showQuestionsOne, setShowQuestionsOne] = useState(false);  // State to track button click
  //loadings
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingqns, setShowLoadingqns] = useState(false);
  const [showLoadingBeforeEq2, setshowLoadingBeforeEq2] = useState(false);
  const [showLoadingfinish, setShowLoadingfinish] = useState(false);

  console.log("message from list", message);

  // Define countdown states for both sections
  const [countdownIntroQuestion, setCountdownIntroQuestion] = useState(15);
  const [countdownDefinition, setCountdownDefinition] = useState(15);
  const [countdownCommoDifference, setCountdownCommoDifference] = useState(15);
  const [countdownExample, setCountdownExample] = useState(15);
  const [countdownFormula, setCountdownFormula] = useState(15);

  const [isIntroQuestionAnimationComplete, setIsIntroQuestionAnimationComplete] = useState(false);
  const [isIntroQuestion2AnimationComplete, setIsIntroQuestion2AnimationComplete] = useState(false);
  const [isDefinitionAnimationComplete, setIsDefinitionAnimationComplete] = useState(false);
  const [isExampleAnimationComplete, setIsExampleAnimationComplete] = useState(false);
  const [isFormulaAnimationComplete, setIsFormulaAnimationComplete] = useState(false);
  const [isQuestionForYouComplete, setisQuestionForYouComplete] = useState(false);
  const [isExampleProblem, setExampleProblem] = useState(false);
  const [isImportance, setisImportance] = useState(false);
  const [isBeforeEq, setisBeforeEq] = useState(false);
  const [isSummary, setisSummary] = useState(false);
  const [isCommonDifference, setisCommonDifference] = useState(false);
  const [isEnsureQn, setisEnsureQn] = useState(false);
  const [isConclusion, setisConclusion] = useState(false);


  const [showNextevaluationsecondOne, setshowNextevaluationsecondOne] = useState(false);
  const [showNextevaluationsecondTwo, setshowNextevaluationsecondTwo] = useState(false);
  const [showNextevaluationsecondThree, setshowNextevaluationsecondThree] = useState(false);
  const [showNextevaluationsecondFour, setshowNextevaluationsecondFour] = useState(false);
  const [showNextevaluationEnsuretwo, setshowNextevaluationEnsuretwo] = useState(false);
  const [shownextExampleProblem, setshownextExampleProblem] = useState(false);
  const [shownextnextSteptwo, setshownextnextSteptwo] = useState(false);
  const [shownviewEquation, setshownviewEquation] = useState(false);


  const [d, setD] = useState("");       // For user input of d
  const [X1, setX1] = useState("");     // For user input of X1
  const [feedback1, setFeedback1] = useState(""); // For feedback on d
  const [feedback2, setFeedback2] = useState(""); // For feedback on X1
  const [answer1, setAnswer1] = useState("");
  const [feedback3, setFeedback3] = useState("");

  const verifyD = () => {
    if (parseInt(d) === 3) {
      setFeedback1("Correct!");
    } else {
      setFeedback1("Incorrect. The correct value of d is 3.");
    }
  };

  const verifyX1 = () => {
    if (parseInt(X1) === 1) {
      setFeedback2("Correct!");
    } else {
      setFeedback2("Incorrect. The correct value of X1 is 1.");
    }
  };
  const verifyAnswer = () => {
    if (parseInt(answer1, 10) === 22) {
      setFeedback3("Correct!");
    } else {
      setFeedback3("Incorrect! Try again.");
    }
  };



  useEffect(() => {
    if (message && message.text && !textPaused) {
      console.log("Message Content:", message.text.evaluationfirstone);
      console.log("Message Content:", message.text.evaluationfirsttwo);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: message.text,
          correctAnswer: message.answer,
          questionbknd: message.question
        }
      ]);
    }
  }, [message, textPaused]);

  //Ensure qns after example
  const handleSubmitEnsureeq1 = () => {
    // Get the correct answer for question 1
    const correctAnswer = questionsEnsure[0].correctAnswer;
    const text = 'ensureeq1'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("ensureeq1 :", feedback)
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentevaluationmalayalam(answer, questionsEnsure[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleSubmitEnsureeq2 = () => {
    // Get the correct answer for question 1
    const correctAnswer = questionsEnsure[1].correctAnswer;
    const text = 'ensureeq2'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("ensureeq2 :", feedback)
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentevaluationmalayalam(answer, questionsEnsure[1].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const [ensureEq1, seteEnsureEq1] = useState("");
  useEffect(() => {
    if (message?.text?.ensureeq1) {
      seteEnsureEq1(message.text.ensureeq1);
    }
  }, [message?.text?.ensureeq1]);
  const [ensureEq2, seteEnsureEq12] = useState("");
  useEffect(() => {
    if (message?.text?.ensureeq2) {
      seteEnsureEq12(message.text.ensureeq2);
    }
  }, [message?.text?.ensureeq2]);



  //Before Equation Qns
  const handleSubmitbeforeq1 = () => {
    // Get the correct answer for question 1
    const correctAnswer = questionsbeforeeq[0].correctAnswer;
    const text = 'beforeeq1'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("beforeeq1 :", feedback)
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentevaluationmalayalam(answer, questionsbeforeeq[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleSubmitbeforeq2 = () => {
    // Get the correct answer for question 1
    const correctAnswer = questionsbeforeeq[1].correctAnswer;
    const text = 'beforeeq2'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    setshowLoadingBeforeEq2(true);
    console.log("beforeeq1 :", feedback)
    // Call studentevaluationmalayalam function with necessary arguments
    studentevaluationmalayalam(answer, questionsbeforeeq[1].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };

  const [beforeEq1, setBeforeEq1] = useState("");
  useEffect(() => {
    if (message?.text?.beforeeq1) {
      setBeforeEq1(message.text.beforeeq1);
    }
  }, [message?.text?.beforeeq1]);
  const [beforeEq2, setbeforeeEq2] = useState("");
  useEffect(() => {
    if (message?.text?.beforeeq2) {
      setbeforeeEq2(message.text.beforeeq2);
    }
  }, [message?.text?.beforeeq2]);

  //Evaluation second full useState and useEffect
  const [answer, setAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [isScoreVisible, setIsScoreVisible] = useState(false);

  const handleSubmitone = () => {
    // Get the correct answer for question 1
    const correctAnswer = questions[0].correctAnswer;
    const text = 'secondone'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    // Call studentevaluationmalayalam function with necessary arguments
    studentevaluationmalayalam(answer, questions[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleSubmittwo = () => {
    const correctAnswer = questions[1].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluationmalayalam(answer, questions[1].question, feedback, 'secondtwo');
    setAnswer("");
  };

  const handleSubmitthree = () => {
    const correctAnswer = questions[2].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluationmalayalam(answer, questions[2].question, feedback, 'secondthree');
    setAnswer("");
  };

  const handleSubmitfour = () => {
    const correctAnswer = questions[3].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluationmalayalam(answer, questions[3].question, feedback, 'secondfour');
    setAnswer("");

    // Mark all questions as answered
    setAllQuestionsAnswered(true);
  };

  const [evaluationSecondone, setEvaluationSecondone] = useState("");
  const [evaluationSecondtwo, setEvaluationSecondtwo] = useState("");
  const [evaluationSecondthree, setEvaluationSecondthree] = useState("");
  const [evaluationSecondfour, setEvaluationSecondfour] = useState("");


  useEffect(() => {
    if (message?.text?.evaluationsecondone) {
      setEvaluationSecondone(message.text.evaluationsecondone);
    }
  }, [message?.text?.evaluationsecondone]);

  useEffect(() => {
    if (message?.text?.evaluationsecondtwo) {
      setEvaluationSecondtwo(message.text.evaluationsecondtwo);
    }
  }, [message?.text?.evaluationsecondtwo]);

  useEffect(() => {
    if (message?.text?.evaluationsecondthree) {
      setEvaluationSecondthree(message.text.evaluationsecondthree);
    }
  }, [message?.text?.evaluationsecondthree]);

  useEffect(() => {
    if (message?.text?.evaluationsecondfour) {
      setEvaluationSecondfour(message.text.evaluationsecondfour);
    }
  }, [message?.text?.evaluationsecondfour]);

  const nextEvaluationSecondone = () => {
    setshowNextevaluationsecondOne(true);
  };
  const nextEvaluationSecondtwo = () => {
    setshowNextevaluationsecondTwo(true);
  };
  const nextEvaluationSecondthree = () => {
    setshowNextevaluationsecondThree(true);
  };
  const nextEvaluationSecondfour = () => {
    setshowNextevaluationsecondFour(true);
  };
  const nextEvaluationEnsuretwo = () => {
    setshowNextevaluationEnsuretwo(true);
  };
  //finished the Evaluation second JS

  const nextViewEquation = () => {
    handleViewEquation(true)
    setshownviewEquation(true);
  };
  const nextExampleProblem = () => {
    handlenextstep1(true)
    setshownextExampleProblem(true);
  };
  const nextSteptwo = () => {
    handlenextstep2(true)
    setshownextnextSteptwo(true);
  };
  const handlenextstep1 = () => {
    // Get the correct answer for question 1
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'nextstep1');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handlenextstep2 = () => {
    // Get the correct answer for question 1
    setshowLoadingBeforeEq2(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'nextstep2');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleViewEquation = () => {
    // Get the correct answer for question 1
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'viewequation');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };

  const handleexampleProblem = () => {
    // Get the correct answer for question 1
    setShowLoading(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'problem');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };


  //typewrite Animation
  // Function to trigger typewriter animation for a specific text part
  const animateText = (fullText, key) => {
    let index = 0;
    setDisplayedText((prev) => ({ ...prev, [key]: '' })); // Reset the text part

    // Set speed conditionally
    const speed =
      (key === "importance") ? 80 : // Speed 110 for "importance" and "example"
        (key === "example" || key === "commondifference") ? 80 : // Speed 110 for "importance" and "example"
          (key === "example_problem" || key === "formula_combined") ? 75 : // Speed 100 for these keys
            70; // Default speed

    const intervalId = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText((prev) => ({
          ...prev,
          [key]: fullText.slice(0, index + 1),
        }));
        index++;
      } else {
        clearInterval(intervalId);
        // Completion handlers for each key
        if (key === "intro_question_1") {
          setIsIntroQuestionAnimationComplete(true);
        }
        if (key === "intro_question_2") {
          setIsIntroQuestion2AnimationComplete(true);
        }
        if (key === "definition") {
          setIsDefinitionAnimationComplete(true);
        }
        if (key === "example") {
          setIsExampleAnimationComplete(true);
        }
        if (key === "formula_combined") {
          setIsFormulaAnimationComplete(true);
        }
        if (key === "questions_for_you_to_solve") {
          setisQuestionForYouComplete(true);
        }
        if (key === "example_problem") {
          setExampleProblem(true);
        }
        if (key === "importance") {
          setisImportance(true);
        }
        if (key === "beforeeq") {
          setisBeforeEq(true);
        }
        if (key === "summary") {
          setisSummary(true);
        }
        if (key === "commondifference") {
          setisCommonDifference(true);
        }
        if (key === "ensureqns") {
          setisEnsureQn(true);
        }
        if (key === "conclusion") {
          setisConclusion(true);
        }
      }
    }, speed); // Use the conditional speed
  };

  // Trigger animations sequentially for all text parts in a message
  const startAnimationForMessage = (messageContent) => {
    const textParts = [
      "greeting",
      "intro_question_1",
      "intro_question_2",
      "definition",
      "example",
      "importance",
      "formula_combined",
      "example_problem",
      "evaluation",
      "questions_for_you_to_solve",
      "conclusion",
      "intraction",
      "beforeeq",
      "summary",
      "commondifference",
      "ensureqns",
    ];

    textParts.reduce((promise, part) => {
      return promise.then(() => {
        if (messageContent[part]) {
          animateText(messageContent[part], part);
          return new Promise((resolve) => setTimeout(resolve, messageContent[part].length * 50 + 500)); // Add delay for typing
        }
        return Promise.resolve();
      });
    }, Promise.resolve());
  };

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      startAnimationForMessage(latestMessage.content);
    }
  }, [messages]);

  //Scorlling
  useEffect(() => {
    if (containerRef.current && !textPaused) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 10);
    }
  }, [messages, textPaused, displayedText, showQuestionsOne1, showQuestionsOne, showQuestions, showQuestionsbeforeEq, showQuestionsEnsureQns, showLoading, showLoadingqns, showLoadingBeforeEq2, showLoadingfinish, showNextevaluationsecondOne, showNextevaluationsecondTwo, showNextevaluationEnsuretwo, showNextevaluationsecondThree,
    isExampleAnimationComplete, isEnsureQn, isConclusion, isQuestionForYouComplete, isExampleProblem, isFormulaAnimationComplete, isBeforeEq, isDefinitionAnimationComplete, isIntroQuestionAnimationComplete, isIntroQuestion2AnimationComplete, isCommonDifference
  ]);



  const renderWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <p key={index} className="mb-2">
        {line}
      </p>
    ));
  };


  const handleDoubtResponseformula = (response) => {
    if (response === 'Yes') {
      // Trigger loading spinner after clicking "Yes"
      setShowLoading(true);

      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'formula');
    } else {
      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    }
  };

  const handleDoubtResponseimp = () => {
    setShowLoadingfinish(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'imp');
    // Optionally, you can add a success message or any other logic you need here.
    // alert("You have finished the questions. Thank you for solving them!");
  };


  const handleDoubtResponseexample = (response) => {
    if (response === 'Yes') {
      // Trigger loading spinner after clicking "Yes"
      setShowLoading(true);

      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'example');
    } else {
      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    }
  };


  // Effect hook to monitor when loading is false (i.e., when the next message has arrived)
  useEffect(() => {
    if (!loading) {
      setShowLoading(false);  // Hide the loading spinner once the loading is complete
      setShowLoadingqns(false)
      setshowLoadingBeforeEq2(false)
      setShowLoadingfinish(false)
    }
  }, [loading]);


  const handleDoubtResponse = (response) => {
    if (response === 'Yes') {
      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    } else {

      // setDoubtResponse("Ok, We can move on to Example Problem.");
      // alert("Ok, We can move on to Example Problem.");
      setShowLoading(true);
      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'yes');

    }
  };

  //First Evaluation Starting 

  {/* Function to handle the answer submission for Yes/No responses */ }
  const handleDoubtResponseoneone = (response, questionId, text = 'firstone') => {
    const question = questionsone.find(q => q.id === questionId);

    if (!question) {
      console.error("Question not found");
      return;
    }
    // Show the loading spinner
    setShowLoadingqns(true);

    // Determine if the response matches the correct answer
    const isCorrect = response === question.correctAnswer;

    // Feedback message based on correctness
    const feedbackMessage = isCorrect
      ? `Correct`
      : `Incorrect`;

    // Send the response, question, and feedback to the backend
    studentevaluationmalayalam(response, question.question, feedbackMessage, text);


  };

  {/* Function to handle the answer submission for Yes/No responses */ }
  const handleDoubtResponseonetwo = (response, questionId, text = 'firsttwo') => {
    const question = questionsone.find(q => q.id === questionId);

    if (!question) {
      console.error("Question not found");
      return;
    }
    setShowLoadingqns(true);

    // Determine if the response matches the correct answer
    const isCorrect = response === question.correctAnswer;

    // Feedback message based on correctness
    const feedbackMessage = isCorrect
      ? `Correct! You choose "${response}".`
      : `Incorrect! The correct answer is "${response}".`;

    // Send the response, question, and feedback to the backend
    studentevaluationmalayalam(response, question.question, feedbackMessage, text);
  };

  const [evaluationFirstText, setEvaluationFirstText] = useState("");
  const [evaluationFirsttwoText, setEvaluationFirsttwoText] = useState("");
  const [showintro2Button, setShowintro2Button] = useState(false); // State to control button visibility
  const [showintro3Button, setShowintro3Button] = useState(false); // State to control button visibility


  useEffect(() => {
    if (message?.text?.evaluationfirstone) {
      setEvaluationFirstText(message.text.evaluationfirstone);
    }
  }, [message?.text?.evaluationfirstone]);

  useEffect(() => {
    if (message?.text?.evaluationfirsttwo) {
      setEvaluationFirsttwoText(message.text.evaluationfirsttwo);
    }
  }, [message?.text?.evaluationfirsttwo]);

  // Show button after evaluationFirstText is set
  useEffect(() => {
    if (evaluationFirstText) {
      setShowintro2Button(true);
    }
  }, [evaluationFirstText]);

  useEffect(() => {
    if (evaluationFirsttwoText) {
      setShowintro3Button(true)
    }
  }, [evaluationFirsttwoText]);

  const handleButtonintro1Click = () => {
    // Handle button click action
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'intro2');
  };
  //First Evaluation Finished 

  const handleFinish = () => {
    setshowLoadingBeforeEq2(true)

    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'finished');
    // Optionally, you can add a success message or any other logic you need here.
  };
  const handleFinishsumary = () => {
    setshowLoadingBeforeEq2(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'summary');
    // Optionally, you can add a success message or any other logic you need here.
  };

  const handleFinishbeforeEq = () => {
    setShowLoadingfinish(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'beforeeq');
    // Optionally, you can add a success message or any other logic you need here.
    // alert("You have finished the questions. Thank you for solving them!");
  };

  // Function to handle the finish action
  const handleFinishone = () => {
    // Trigger loading state to show the loading spinner
    setShowLoading(true);

    // Call the student chat UI to trigger the next message
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'definition');
  };

  // Effect hook to monitor when loading is false (i.e., when the next message has arrived)
  useEffect(() => {
    if (!loading) {
      setShowLoading(false);  // Hide the loading spinner once the loading is complete
      setShowLoadingqns(false);
      setshowLoadingBeforeEq2(false)
      setShowLoadingfinish(false)
    }
  }, [loading]);


  // Countdown logic for intro_question_1 and definition
  useEffect(() => {
    if (isIntroQuestionAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isIntroQuestionAnimationComplete]);

  useEffect(() => {
    if (isIntroQuestion2AnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isIntroQuestion2AnimationComplete]);
  useEffect(() => {
    if (isDefinitionAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownDefinition((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isDefinitionAnimationComplete]);

  // Countdown logic for the example section
  useEffect(() => {
    if (isExampleAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownExample((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isExampleAnimationComplete]);

  // Countdown logic for the formula_combined section
  useEffect(() => {
    if (isFormulaAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownFormula((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isFormulaAnimationComplete]);
  // Countdown logic for the formula_combined section
  useEffect(() => {
    if (isQuestionForYouComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isQuestionForYouComplete]);

  useEffect(() => {
    if (isImportance) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isImportance]);

  useEffect(() => {
    if (isExampleProblem) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isExampleProblem]);

  useEffect(() => {
    if (isBeforeEq) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isBeforeEq]);
  useEffect(() => {
    if (isSummary) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isSummary]);
  useEffect(() => {
    if (isCommonDifference) {
      const countdownInterval = setInterval(() => {
        setCountdownCommoDifference((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCommonDifference]);
  useEffect(() => {
    if (isEnsureQn) {
      const countdownInterval = setInterval(() => {
        setCountdownCommoDifference((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isEnsureQn]);

  useEffect(() => {
    if (isConclusion) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isConclusion]);


  const handleInputChange = (index, value) => {
    const newInputAnswers = [...inputAnswers];
    newInputAnswers[index] = value;
    setInputAnswers(newInputAnswers);
  };
  const handleViewScoreAndSubmit = () => {
    // Show the score
    setIsScoreVisible(true);
    setShowLoading(true);

    // Calculate percentage
    const percentage = ((score / questions.length) * 100).toFixed(2);
    // Call the handleSubmitthree function
    advice(percentage);
    sendPercentageToBackend(profileData, percentage);


  };

  const advice = (percentage) => {
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, percentage);
  };
  const [adviceMessage, setadviceMessage] = useState("");


  useEffect(() => {
    if (message?.text?.advice) {
      setadviceMessage(message.text.advice);
    }
  }, [message?.text?.advice]);


  const handleClick = () => {
    setShowQuestions(true);
  };
  const handleClickbeforeEq = () => {
    setshowQuestionsbeforeEq(true);
  };
  const handleClickEnsureQn = () => {
    setshowQuestionsEnsureQns(true);
  };



  return (
    <Html position={[0.3, -0.05, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[1288px] h-[676px] p-8 overflow-y-auto bg-transparent opacity-80"
        ref={containerRef}
      >
        {messages.length === 0 ? (
          <div className="h-full w-full flex flex-col items-center justify-center text-center space-y-4">
            <h2 className="text-8xl font-bold text-white/90 italic">
              School of
            </h2>
            <h2 className="text-8xl font-bold font-jp text-red-600/90 italic">
              wallPiller
            </h2>
            <br />

            {/* Add the loading spinner below the text */}
            <div className="mt-6 flex justify-center items-center space-x-4">
              <span className="text-5xl font-semibold text-white">Class starting soon...</span>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="3x" // Increase spinner size to make it more prominent
                className="text-indigo-500" // Adjust the color if needed
              />
            </div>
          </div>

        ) : (
          <div className="flex flex-col space-y-4">
            {messages.map((msg, index) => (
              <div
                key={index}
              >
                {msg.content.greeting && (
                  <h2 className="text-4xl font-bold text-green-400 mb-2">
                    {displayedText.greeting || ''}
                  </h2>
                )}
                {msg.content.intro_question_1 && (
                  <div className="mt-4 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.intro_question_1 || "").split("\n");

                        return lines.map((line, index) => {
                          if (line.trim() === "1, 4, 9, 16, 25, ...") {
                            return (
                              <p key={index} className="mb-2 text-blue-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.startsWith("Question 1:")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.trim() === "") {
                            return <br key={index} />;
                          }

                          return (
                            <p key={index} className="mb-2 text-white">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isIntroQuestionAnimationComplete && (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={() => setShowQuestionsOne1(true)}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click Here
                        </button>
                      </div>
                    )}

                    {/* Only show questions if the button is clicked */}
                    {showQuestionsOne1 && (
                      <div className="mt-8 bg-gray-700 px-8 py-8 rounded-lg shadow-sm text-center">
                        {questionsone
                          .filter((question) => question.id === 1)
                          .map((question) => (
                            <div key={question.id} className="mt-4">
                              <div className="text-4xl font-bold text-white-300">
                                {question.question}
                              </div>
                              <div className="mt-4 flex justify-center space-x-4">
                                <button
                                  onClick={() => handleDoubtResponseoneone('Yes', question.id)}
                                  className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={() => handleDoubtResponseoneone('No', question.id)}
                                  className="bg-red-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* // Show loading spinner after clicking Finish */}
                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}

                    <br />

                    {/* Add evaluationFirstText below intro_question_1 without affecting the layout */}
                    <div className="mt-4 text-4xl font-bold text-gray-300">
                      {evaluationFirstText && <p>{evaluationFirstText}</p>}
                    </div>

                    {showintro2Button && (  // Conditionally render the button based on evaluationFirstText
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={() => {
                            setShowLoading(true); // Show the loading spinner when the button is clicked
                            handleButtonintro1Click(); // Call the function to handle the finish action
                          }}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Finish
                        </button>
                      </div>
                    )}

                    {/* // Show loading spinner after clicking Finish */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white"> </span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}


                  </div>
                )}

                {msg.content.intro_question_2 && (
                  <div className="mt-4 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {/* Process and render content */}
                      {(() => {
                        // Split the content into lines
                        const lines = (displayedText.intro_question_2 || "").split("\n");

                        // Find the start of "Question 1:" and collect subsequent lines
                        let isQuestion2 = false;
                        return lines.map((line, index) => {
                          // Apply different styles based on the content
                          if (line.trim() === "Here’s a list: 2, 3, 4 ...") {
                            return (
                              <p key={index} className="mb-2 text-blue-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.startsWith("Question 2:")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.trim() === "") {
                            // Render a line break for empty lines
                            return <br key={index} />;
                          }

                          // Default styling for other lines
                          return (
                            <p key={index} className="mb-2 text-white">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isIntroQuestion2AnimationComplete && (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={() => setShowQuestionsOne(true)}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click Here
                        </button>
                      </div>
                    )}

                    {/* Only show questions if the button is clicked */}
                    {showQuestionsOne && (
                      <div className="mt-8 bg-gray-700 px-8 py-8 rounded-lg shadow-sm text-center">
                        {questionsone
                          .filter((question) => question.id === 2)
                          .map((question) => (
                            <div key={question.id} className="mt-4">
                              <div className="text-4xl font-bold text-white-300">
                                {question.question}
                              </div>
                              <div className="mt-4 flex justify-center space-x-4">
                                <button
                                  onClick={() => handleDoubtResponseonetwo('Yes', question.id)}
                                  className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={() => handleDoubtResponseonetwo('No', question.id)}
                                  className="bg-red-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}
                    {/* Add evaluationFirsttwoText below intro_question_1 without affecting the layout */}
                    <div className="mt-4 text-4xl font-bold text-gray-300">
                      {evaluationFirsttwoText && <p>{evaluationFirsttwoText}</p>}
                    </div>
                    {/* Finish Button */}
                    {showintro3Button && (  // Conditionally render the button based on evaluationFirsttwoText
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={handleFinishone}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Finish
                        </button>
                      </div>
                    )}

                    {/* Next Definition and Loading Icon */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Next Definition Text */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can vary the color here
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.definition && (
                  <div className="mt-4 border-4 border-yellow-500 bg-gray-00 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2">Note</h3>
                    <div className="text-5xl font-bold">
                      {(() => {
                        // Safely access and split 'definition' into lines from the animated text
                        const lines = (displayedText.definition || "").split("\n");

                        return lines.map((line, index) => {
                          // Define default style for the entire line
                          let lineStyle = "text-white";
                          if (
                            line.trim() ===
                            "- ഇവിടെ രണ്ടാമത്തെ ശ്രേണിയിൽ ഒരു പൊതു വ്യത്യാസം ഉണ്ട്."
                          ) {
                            lineStyle = "text-yellow-400";
                          }

                          return (
                            <React.Fragment key={index}>
                              {/* Add a line break before specific lines */}
                              {line.trim() ===
                                "- ഇവിടെ രണ്ടാമത്തെ ശ്രേണിയിൽ ഒരു പൊതു വ്യത്യാസം ഉണ്ട്." && (
                                  <br />
                                )}
                              <p className={`mb-2 ${lineStyle}`}>
                                {line.split(/(1, 4, 9, 16, 25\.\.\.|2,3,4)/).map((part, partIndex) => {
                                  // Check for specific sequences and apply distinct color
                                  if (part === "1, 4, 9, 16, 25..." || part === "2,3,4") {
                                    return (
                                      <span key={partIndex} className="text-yellow-400">
                                        {part}
                                      </span>
                                    );
                                  }
                                  // Return the rest of the line in default style
                                  return <span key={partIndex}>{part}</span>;
                                })}
                              </p>
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>

                    {/* Countdown for definition */}
                    {isDefinitionAnimationComplete && countdownDefinition > 0 && (
                      <div className="bg-gray-700 justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <h2 className="text-3xl font-semibold">
                            <i className="fas fa-hourglass-half text-yellow-500 animate-spin"></i>എന്താണ് പൊതു വ്യത്യാസം...
                          </h2>
                          <p className="mt-2">
                            <span className="inline-block text-xl bg-gray-700 text-yellow-400 font-bold rounded-full px-3 py-1 shadow">
                              {countdownDefinition}
                              <span className="ml-1 text-xl text-gray-300">seconds</span>
                            </span>
                          </p>
                        </div>
                        <div>
                          <i className="fas fa-question-circle text-4xl text-yellow-500"></i>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.commondifference && (
                  <div className="mt-4 bg-gray-00 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold">
                      {(() => {
                        // Safely access and split 'commondifference' into lines from the animated text
                        const lines = (displayedText.commondifference || "").split("\n");

                        return lines.map((line, index) => {
                          // Define default style for the entire line
                          let lineStyle = "text-white";

                          // Highlight specific lines
                          if (line.trim() === "- ഇത്തരത്തിൽ പൊതു വ്യത്യാസമുള്ള ശ്രേണികളെ സമാന്തര ശ്രേണി എന്ന് വിളിക്കുന്നു.") {
                            lineStyle = "text-yellow-400";
                          }

                          return (
                            <React.Fragment key={index}>
                              {/* Add a line break and specific color for the first and second sequence lines */}
                              {line.trim() === "ആദ്യത്തെ 1, 4, 9, 16, 25... എന്ന ശ്രേണി നോക്കൂ." && (
                                <p className="mb-2 text-yellow-400">
                                  {line}
                                </p>
                              )}

                              {/* Add a line break before "Second sequence 2,3,4..." */}
                              {line.trim() === "രണ്ടാമത്തെ ശ്രേണിയിൽ 2,3,4..." && (
                                <>
                                  <br /> {/* Add a line break here */}
                                  <p className="mb-2 text-yellow-400">
                                    {line}
                                  </p>
                                </>
                              )}

                              {/* Line handling for "So The difference of neighbouring terms are not constant." */}
                              {line.trim() === "അതിനാൽ അടുത്തടുത്തുള്ള പദങ്ങളുടെ വ്യത്യാസം സ്ഥിരമല്ല." && (
                                <p className={`mb-2 ${lineStyle}`}>{line}</p>
                              )}

                              {/* Line break after the specified line */}
                              {line.trim() === "അതിനാൽ അടുത്തടുത്തുള്ള പദങ്ങളുടെ വ്യത്യാസം സ്ഥിരമാണ്. അതായത് ഒരു പദത്തിൽ നിന്ന് തൊട്ടു പുറകിലെ പദം കുറച്ചാൽ വ്യത്യാസങ്ങളെല്ലാം സ്ഥിരം ആയിരിക്കും. ഇതിനെ പൊതു വ്യത്യാസം എന്നു വിളിക്കുന്നു.2,3,4 ശ്രേണിയിൽ 1 ആണ് പൊതു വ്യത്യാസം." && (
                                <>
                                  <p className={`mb-2 ${lineStyle}`}>{line}</p>
                                  <br />
                                </>
                              )}

                              {/* Default handling for other lines */}
                              {line.trim() !== "ആദ്യത്തെ 1, 4, 9, 16, 25... എന്ന ശ്രേണി നോക്കൂ." &&
                                line.trim() !== "രണ്ടാമത്തെ ശ്രേണിയിൽ 2,3,4..." &&
                                line.trim() !== "അതിനാൽ അടുത്തടുത്തുള്ള പദങ്ങളുടെ വ്യത്യാസം സ്ഥിരമല്ല." &&
                                line.trim() !== "അതിനാൽ അടുത്തടുത്തുള്ള പദങ്ങളുടെ വ്യത്യാസം സ്ഥിരമാണ്. അതായത് ഒരു പദത്തിൽ നിന്ന് തൊട്ടു പുറകിലെ പദം കുറച്ചാൽ വ്യത്യാസങ്ങളെല്ലാം സ്ഥിരം ആയിരിക്കും. ഇതിനെ പൊതു വ്യത്യാസം എന്നു വിളിക്കുന്നു.2,3,4 ശ്രേണിയിൽ 1 ആണ് പൊതു വ്യത്യാസം." && (
                                  <p className={`mb-2 ${lineStyle}`}>
                                    {line.split(/(1, 4, 9, 16, 25, \.\.\.|2,3,4\.\.)/).map((part, partIndex) => {
                                      // Check for specific sequences and apply distinct color
                                      if (part === "1, 4, 9, 16, 25..." || part === "2,3,4...") {
                                        return (
                                          <span key={partIndex} className="text-yellow-400">
                                            {part}
                                          </span>
                                        );
                                      }
                                      // Return the rest of the line in default style
                                      return <span key={partIndex}>{part}</span>;
                                    })}
                                  </p>
                                )}
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>


                    {/* Countdown for definition */}
                    {isCommonDifference && (
                      <div className="mt-6  bg-opacity-70 p-6 px-3 py-2 rounded-lg shadow-lg">
                        <h3 className="text-3xl text-gray-300 font-semibold text-center">
                          Do you get me?
                        </h3>
                        <div className="mt-4 flex justify-center space-x-4">
                          <button
                            onClick={() => handleDoubtResponseexample("Yes")}
                            className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleDoubtResponseexample("No")}
                            className="bg-red-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {/* // Show loading spinner after clicking Finish */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">അടുത്തത് കുറച്ചു ഉദാഹരണങ്ങൾ നോക്കാം...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.example && (
                  <div className="mt-4 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.example || "").split("\n");
                        return lines.map((line, index) => {
                          let lineClass = "text-white:"; // Default class
                          if (line.startsWith("ഉദാഹരണത്തിന്:")) {
                            lineClass = "text-yellow-300"; // Style for Number example
                          } else if (line.startsWith("ദൈനംദിന ജീവിതത്തിനുള്ള ഉദാഹരണം:")) {
                            lineClass = "text-green-500"; // Style for Real life example
                          }
                          return (
                            <p key={index} className={`mb-2 ${lineClass}`}>
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {/* Countdown for definition */}
                    {isExampleAnimationComplete && countdownExample > 0 && (
                      <div className="bg-gray-700 justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <h2 className="text-3xl font-semibold">
                            <i className="fas fa-hourglass-half text-yellow-500 animate-spin"></i>ചുവടെയുള്ള ചോദ്യങ്ങൾ പരിഹരിക്കുക...
                          </h2>
                          <p className="mt-2">
                            <span className="inline-block bg-gray-700 text-yellow-400 font-bold rounded-full px-3 py-1 shadow">
                              {countdownExample}
                              <span className="ml-1 text-sm text-gray-300">seconds left</span>
                            </span>
                          </p>
                        </div>
                        <div>
                          <i className="fas fa-question-circle text-4xl text-yellow-500"></i>
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {msg.content.ensureqns && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'conclusion' into lines from the animated text
                        const lines = (displayedText.ensureqns || "").split("\n");

                        // Loop through each line and render it with styles
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    {isEnsureQn && (
                      <button
                        onClick={handleClickEnsureQn}
                        className="bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                      >
                        Click here to solve
                      </button>
                    )}
                    {showQuestionsEnsureQns && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsEnsure[0].question}</p>
                          <input
                            type="text"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center mb-4">
                          <button
                            onClick={handleSubmitEnsureeq1}
                            className="w-full bg-blue-500 text-3xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                          >
                            Submit Answer
                          </button>
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingqns && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="text-center mt-6">
                          {ensureEq1 && (
                            <p className="text-4xl font-semibold text-gray-300">{ensureEq1}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {ensureEq1 && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={nextEvaluationEnsuretwo}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Next
                        </button>
                      </div>
                    )}

                    {showNextevaluationEnsuretwo && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsEnsure[1].question}</p>
                          <input
                            type="text" // Change the input type to text
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 font-bold"
                            placeholder="Enter your sequence (e.g., 1, 2, 3)"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />

                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center mb-4">
                          <button
                            onClick={handleSubmitEnsureeq2}
                            className="w-full bg-blue-500 text-3xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                          >
                            Submit Answer
                          </button>
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingBeforeEq2 && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="mt-6">
                          {ensureEq2 && (
                            <div className="text-4xl font-semibold text-gray-300">
                              {ensureEq2.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </div>
                          )}
                        </div>

                      </div>
                    )}
                    {ensureEq2 && (
                      <div className="mt-6 justify-center"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={handleDoubtResponseimp}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Finish
                        </button>
                      </div>

                    )}
                    {showLoadingfinish && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">സമാന്തരശ്രേണിയിലെ പദങ്ങൾ കണ്ടുപിടിക്കാം...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.importance && (
                  <div className="mt-4 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.importance || "").split("\n");

                        return lines.map((line, index) => {
                          let colorClass = ""; // Class for specific sections of the text
                          let additionalStyle = {}; // Additional styles like line breaks

                          // Apply styles based on specific keywords or sections
                          if (line.startsWith("1, 4, 7, 10... എന്ന ശ്രേണി നോക്കുക.")) {
                            colorClass = "text-white-500";
                          } else if (line.startsWith("Common Difference:")) {
                            colorClass = "text-yellow-400 font-bold";
                          } else if (line.startsWith("  - ")) {
                            colorClass = "text-yellow-400";
                          } else if (line.startsWith("ചോദ്യം:")) {
                            colorClass = "text-blue-500 font-bold";
                          } else if (line.startsWith("അങ്ങനെയാണെങ്കിൽ നിങ്ങൾക്കുള്ള ചോദ്യം:")) {
                            colorClass = "text-blue-500 font-bold";
                          } else if (line.startsWith("ഉത്തരം:")) {
                            colorClass = "text-yellow-400 font-bold";
                            additionalStyle = { marginBottom: "1rem" };
                          } else if (line.trim() === "") {
                            // Render a line break for empty lines
                            return <br key={index} />;
                          }

                          // Render each line with appropriate styles
                          return (
                            <p
                              key={index}
                              className={`mb-2 ${colorClass ? colorClass : "text-white"}`}
                              style={additionalStyle}
                            >
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isImportance && (
                      <button
                        onClick={handleClickbeforeEq}
                        className="bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                      >
                        Click here to solve
                      </button>
                    )}
                    {showQuestionsbeforeEq && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-5xl font-semibold text-white mb-4">{questionsbeforeeq[0].question}</p>
                          <input
                            type="number"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center mb-4">
                          <button
                            onClick={handleSubmitbeforeq1}
                            className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                          >
                            Submit Answer
                          </button>
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingqns && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="text-center mt-6">
                          {beforeEq1 && (
                            <p className="text-4xl font-semibold text-gray-300">{beforeEq1}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {beforeEq1 && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-5xl font-semibold text-white mb-4">{questionsbeforeeq[1].question}</p>
                          <input
                            type="number"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center mb-4">
                          <button
                            onClick={handleSubmitbeforeq2}
                            className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                          >
                            Submit Answer
                          </button>
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingBeforeEq2 && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="mt-6">
                          {beforeEq2 && (
                            <div className="text-4xl font-semibold text-gray-300">
                              {beforeEq2.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </div>
                          )}
                        </div>

                      </div>
                    )}
                    {beforeEq2 && (
                      <div className="mt-6  justify-center"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={handleFinishbeforeEq}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Finish
                        </button>
                      </div>

                    )}
                    {showLoadingfinish && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">സമാന്തര ശ്രേണിയിലെ വലിയ പദങ്ങൾ എങ്ങനെ കാണാം...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.beforeeq && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'beforeeq' into lines from the backend text
                        const lines = (displayedText.beforeeq || "").split("\n");

                        return lines.flatMap((line, index) => {
                          // Array to hold current line and optional line break
                          const elements = [];

                          // Add the current line
                          if (line.includes("ഇതിന് നമ്മൾ പൊതുവേ n-ാം പദം എന്ന് പറയുന്നു")) {
                            elements.push(
                              <p key={index} className="mb-2 text-yellow-500">
                                {line}
                              </p>
                            );
                          } else {
                            elements.push(
                              <p key={index} className="mb-2">
                                {line}
                              </p>
                            );
                          }

                          // Add a line break after a specific line
                          if (
                            line.includes("ഇത് കുറച്ചു ബുദ്ധിമുട്ടാണ് അല്ലേ?") // Match the line
                          ) {
                            elements.push(<br key={`${index}-break`} />);
                          }

                          return elements;
                        });
                      })()}
                    </div>


                    {isBeforeEq && (
                      <div className="mt-6  p-6 px-3 py-2 rounded-lg">
                        <h3 className="text-4xl text-gray-300 font-semibold text-center">
                          Do you get me?
                        </h3>
                        <div className="mt-4 flex justify-center space-x-4">
                          <button
                            onClick={() => handleDoubtResponseformula("Yes")}
                            className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => handleDoubtResponseformula("No")}
                            className="bg-red-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}
                    {/* Display loading icon if showLoading is true and 'Yes' was clicked */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">അടുത്തതായി സമവാക്യം നോക്കാം...</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}
                    {/* {doubtResponse && (
                        <div className="mt-3 text-center text-lg font-bold text-green-400 bg-gray-800 p-3 rounded-md">
                          {doubtResponse}
                        </div>
                      )} */}
                  </div>
                )}
                {msg.content.formula_combined && (
                  <div className="mt-4 border-4 border-yellow-500 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-3xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        // Safely split and render content with optional section titles
                        const lines = (displayedText.formula_combined || "").split("\n");

                        return lines.map((line, index) => {
                          let lineStyle = "text-white"; // Default color

                          // Highlight specific line content
                          if (line.trim() === "Xn= X1 + ((n -1) ×d)") {
                            lineStyle = "text-yellow-400"; // Yellow color for specific formula
                          } else if (line.trim().startsWith("- ")) {
                            lineStyle = "text-yellow-500"; // Yellow color for lines starting with '- '
                          }

                          return (
                            <p key={index} className={`mb-2 ${lineStyle}`}>
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {/* Countdown for formula_combined */}
                    {isFormulaAnimationComplete && (
                      <div className="justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <button
                            onClick={nextViewEquation}
                            className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            View Equation
                          </button>

                        </div>

                      </div>
                    )}
                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white"></span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownviewEquation && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg shadow-lg">
                        {/* Dummy Message */}
                        <div className="text-yellow-500 text-5xl font-bold  text-white py-4 rounded-md">
                          <p>Xn = X1 + ((n - 1) × d)</p>
                          <p> - നമുക്ക് കണ്ടെത്തേണ്ട പദം n </p>
                          <p> - ആദ്യപദം X1</p>
                          <p> - പൊതു വ്യത്യാസം d</p>

                        </div>
                      </div>
                    )}

                    {shownviewEquation && (
                      <div className="justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <button
                            onClick={handleexampleProblem}
                            className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                          >
                            Do a Problem
                          </button>

                        </div>

                      </div>
                    )}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">ഈ സമവാക്യം വെച്ച് ഒരു ചോദ്യം ചെയ്യാം...</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}


                  </div>
                )}

                {msg.content.example_problem && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-3xl font-bold text-gray-300 underline mb-2"></h3>
                    <br />
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.example_problem || "").split("\n"); // Split by single newline
                        let processedLines = [];

                        for (let i = 0; i < lines.length; i++) {
                          const line = lines[i];
                          const nextLine = lines[i + 1];

                          // Check for \n\n: Insert a line break for double newlines
                          if (line === "" && nextLine === "") {
                            processedLines.push(<br key={`gap-${i}`} />);
                            i++; // Skip the next line as part of \n\n
                            continue;
                          }

                          // Check if the current line needs to be followed by an extra line break
                          const needsExtraBreak =
                            line.includes("ചുവടെയുള്ള ശ്രേണിയുടെ 8-ാം മത്തെ പദം കണ്ടുപിടിക്കാമോ ?") ||
                            line.includes("സമവാക്യം, Xn= X1 +((n -1) ×d).") ||
                            line.includes("ആദ്യ പദം X1 = 1.");

                          // Determine the color class for steps
                          const isStep1 = line.includes("1,4,7,10 ...");
                          const isStep2 = line.includes("Step 2:");
                          const stepColorClass = isStep1
                            ? "text-blue-500 font-bold"
                            : isStep2
                              ? "text-green-500 font-bold"
                              : "text-white-300";

                          // Push the current line with styling
                          if (line.trim() !== "") {
                            processedLines.push(
                              <p key={i} className={`mb-2 ${stepColorClass}`}>
                                {line}
                              </p>
                            );
                          }

                          // Add an extra line break if the line requires it
                          if (needsExtraBreak) {
                            processedLines.push(<br key={`extra-break-${i}`} />);
                          }
                        }

                        return processedLines;
                      })()}
                    </div>
                    {isExampleProblem && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={nextExampleProblem}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Answer
                        </button>
                      </div>
                    )}
                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing Feedack, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownextExampleProblem && (
                      <div className="mt-6  p-6 px-3 py-2 rounded-lg ">
                        {/* Display the static text */}
                        <div className="text-5xl font-bold text-white">
                          <h3>ശ്രേണി = 1, 4, 7, 10 ...</h3>

                          <h3>സമവാക്യം, Xn = X1 + ((n - 1) × d).</h3>
                          <h3>Step 1: പൊതു വ്യത്യാസം കണ്ടെത്തുക (d)</h3>
                          <br />
                        </div>

                        {/* Input fields for d and X1 */}
                        <div className="mt-4">
                          {/* Input for "d" */}
                          <div className="flex items-center mb-4">

                            <h3 className="text-white text-4xl font-bold mr-2">പൊതു വ്യത്യാസം d = </h3>
                            <input
                              type="text"
                              className="p-1 w-24 text-3xl font-bold rounded-md border border-gray-300 text-black bg-white"
                              value={d}
                              onChange={(e) => setD(e.target.value)}
                            />
                            <button
                              onClick={verifyD}
                              className="text-2xl font-bold ml-4 p-2 bg-blue-500 text-white rounded-md"
                            >
                              Verify
                            </button>
                            {feedback1 && <p className="ml-4 text-2xl font-bold text-red-500 mt-2">{feedback1}</p>}
                          </div>

                          {/* Input for "X1" */}
                          <div className="flex items-center">
                            <h3 className="text-4xl font-bold text-white mr-2">ആദ്യ പദം X1 = </h3>
                            <input
                              type="text"
                              className="p-1 w-24 text-3xl font-bold rounded-md border border-gray-300 text-black bg-white"
                              value={X1}
                              onChange={(e) => setX1(e.target.value)}
                            />
                            <button
                              onClick={verifyX1}
                              className="ml-4 p-2 text-2xl font-bold bg-blue-500 text-white rounded-md"
                            >
                              Verify
                            </button>
                            {feedback2 && <p className="ml-4 text-2xl font-bold text-red-500 mt-2">{feedback2}</p>}
                          </div>
                        </div>
                      </div>
                    )}


                    {feedback2 && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={nextSteptwo}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Step 2
                        </button>
                      </div>
                    )}
                    {showLoadingBeforeEq2 && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing Feedack, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownextnextSteptwo && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg">
                        {/* Step 2 Static Content */}
                        <div className="text-5xl font-bold text-white mb-4">
                          <h3>Step 2: nth പദം കാണാനുള്ള സമവാക്യം</h3>
                          <h3>Xn = X1 + ((n - 1) × d)</h3>
                          <h3>X8 = X1 + ((n - 1) × d).</h3>
                          <h3>X8 = 1 + ((8 - 1) × 3)</h3>
                        </div>

                        {/* Input Field and Verify Button in One Line */}
                        <div className="flex items-center text-3xl font-bold text-white">
                          <h3 className="mr-2">8-ാം മത്തെ പദം =</h3>
                          <input
                            type="text"
                            className="p-1 w-24 text-3xl font-bold rounded-md border border-gray-300 text-black bg-white"
                            value={answer1}
                            onChange={(e) => setAnswer1(e.target.value)}
                          />
                          <button
                            onClick={verifyAnswer}
                            className="ml-4 p-2 bg-blue-500 text-white rounded-md"
                          >
                            Verify
                          </button>
                        </div>

                        {/* Feedback */}
                        {feedback3 && (
                          <p
                            className={`mt-2 ${feedback3 === "Correct!" ? "text-green-500" : "text-red-500"
                              } text-2xl font-bold`}
                          >
                            {feedback3}
                          </p>
                        )}
                      </div>
                    )}


                    {shownextnextSteptwo && (
                      <div className="mt-6  bg-opacity-70 p-6 px-3 py-2 rounded-lg shadow-lg">
                        {/* Practice Reminder */}
                        <div className="mb-4">
                          {/* <h3 className="text-3xl text-gray-300 font-semibold text-center">
                            Take a paper and write on it ...
                          </h3> */}
                        </div>

                        {/* Do You Get It Section */}
                        <div>
                          <h3 className="text-4xl font-semibold text-center">
                            Do you have any doubt?
                          </h3>

                          <div className="mt-4 flex justify-center space-x-4">
                            <button
                              onClick={() => handleDoubtResponse("Yes")}
                              className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleDoubtResponse("No")}
                              className="bg-red-500 text-white text-3xl px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>

                    )}

                    {/* Display loading icon if showLoading is true and 'Yes' was clicked */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">നിങ്ങൾക് പരിഹരിക്കാനുള്ള ചോദ്യങ്ങൾ ചുവടെ നൽകുന്നു...</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.questions_for_you_to_solve && (
                  <div className="mt-4">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">
                    </h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        // Render animated text with line breaks
                        const lines = (displayedText.questions_for_you_to_solve || "").split("\n");
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    <div className="mt-4">
                      {isQuestionForYouComplete && (
                        <button
                          onClick={handleClick}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click here to solve
                        </button>
                      )}
                      {showQuestions && (
                        <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                          {/* Display question and input field for the first question */}
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[0].question}</p>
                            <input
                              type="number"
                              className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>

                          {/* Submit Button */}
                          <div className="flex justify-center mb-4">
                            <button
                              onClick={handleSubmitone}
                              className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                            >
                              Submit Answer
                            </button>
                          </div>

                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          {/* Evaluation Message Section */}
                          <div className="mt-6">
                            {evaluationSecondone && (
                              <div className="text-4xl font-semibold text-gray-300">
                                {evaluationSecondone.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>

                          {evaluationSecondone && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondone}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {/* Display second question after submitting the first */}
                      {showNextevaluationsecondOne && (
                        <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[1].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          <div className="flex justify-center mb-8">
                            <button
                              onClick={handleSubmittwo}
                              className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                            >
                              Submit Answer
                            </button>
                          </div>

                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondtwo && (
                              <div className="text-4xl font-semibold text-gray-300">
                                {evaluationSecondtwo.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                          {evaluationSecondtwo && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondtwo}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                      )}


                      {/* Display third question after submitting the second */}
                      {showNextevaluationsecondTwo && (
                        <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[2].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          <div className="flex justify-center mb-8">
                            <button
                              onClick={handleSubmitthree}
                              className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                            >
                              Submit Answer
                            </button>
                          </div>
                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondthree && (
                              <div className="text-4xl font-semibold text-gray-300">
                                {evaluationSecondthree.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                          {evaluationSecondthree && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondthree}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Display fourth question after submitting the third */}
                      {showNextevaluationsecondThree && (
                        <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[3].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          <div className="flex justify-center mb-8">
                            <button
                              onClick={handleSubmitfour}
                              className="w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-200 transform hover:scale-105 font-bold"
                            >
                              Submit Answer
                            </button>
                          </div>
                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondfour && (
                              <div className="text-4xl font-semibold text-gray-300">
                                {evaluationSecondfour.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {evaluationSecondfour && (
                        <div className="mt-4">
                          {allQuestionsAnswered && !isScoreVisible && (
                            <div className="mt-4">
                              <button
                                onClick={handleViewScoreAndSubmit}

                                className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                View Score
                              </button>
                            </div>
                          )}
                          <br />
                          {showLoading && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing Feedack, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          {isScoreVisible && (
                            <div className="flex justify-center items-center">
                              <div className="text-center border-4 border-white-500 bg-gray-00 p-6 rounded-lg shadow-lg">
                                <p className="text-3xl font-bold font-jp text-yellow-500/90 italic">
                                  {`${totalQuestions} ചോദ്യങ്ങളിൽ ${score} എണ്ണം  ശരി ആണ്.`}
                                </p>
                                <p>
                                  <span className="text-2xl font-bold text-white/90 italic">Your percentage: </span>
                                  <span className="text-3xl font-bold font-jp text-green-500/90 italic">
                                    {`${((score / questions.length) * 100).toFixed(2)}%`}
                                  </span>
                                </p>
                                <div className="mt-6">
                                  <div className="mt-4 text-3xl font-bold text-gray-300">
                                    {adviceMessage && <p>{adviceMessage}</p>}
                                  </div>
                                </div>
                                {/* Finish Button Below */}
                                <div className="mt-6"> {/* Adding margin-top for spacing */}
                                  <button
                                    onClick={handleFinishsumary}
                                    className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                                  >
                                    Finish
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {showLoadingBeforeEq2 && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* {msg.content.evaluation && (
                  <div className="mt-4 bg-gray-900 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Evaluation</h3>
                    <div className="text-3xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.evaluation || "").split("\n");
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">{line}</p>
                        ));
                      })()}
                    </div>
                    <div className="mt-6 bg-gray-900 px-3 py-2 rounded-md shadow-sm text-center">
                      <p className="text-lg font-bold text-gray-300">
                        After submitting 4 answers, please click on the
                        <span className="text-green-600 font-semibold"> "Finish" </span> button above to proceed.
                      </p>
                    </div>
                  </div>
                )} */}

                {msg.content.summary && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Summary</h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'summary' into lines from the backend text
                        const lines = (displayedText.summary || "").split("\n");

                        // Process each line to handle styles and line breaks
                        return lines.map((line, index) => {
                          // Add a larger line break after "What Have We Learned Today?"
                          if (line.trim() === "What Have We Learned Today?") {
                            return (
                              <React.Fragment key={index}>
                                <p className="mb-4">{line}</p>
                                <br /> {/* Add a line break */}
                              </React.Fragment>
                            );
                          }

                          // Apply specific styles for numbered points
                          if (line.startsWith("1.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }
                          if (line.startsWith("2.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }
                          if (line.startsWith("3.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }

                          // Default white styling for other lines
                          return (
                            <p key={index} className="mb-2">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>

                    {isSummary && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={handleFinish}
                          className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Finish
                        </button>
                      </div>
                    )}
                    {showLoadingBeforeEq2 && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {msg.content.conclusion && (
                  <div className="mt-4 border-4 border-white-500 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Conclusion</h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'conclusion' into lines from the animated text
                        const lines = (displayedText.conclusion || "").split("\n");

                        // Loop through each line and render it with styles
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    {isConclusion && (
                      <div className="mt-8 flex flex-col items-center space-y-6">
                        <div className="text-center">
                          <h3 className="text-3xl font-bold text-gray-300 mb-4">
                            Thank you for completing this chapter!
                          </h3>
                          <p className="text-xl text-gray-400">
                            You can download the summary notes for future reference.
                          </p>
                        </div>
                        {/* Add the PDF download button */}
                        <PdfDownloadButton />
                      </div>
                    )}
                  </div>


                )}



              </div>
            ))}
          </div>
        )}
      </div>
    </Html>
  );
};

export default StMessListTMMalayalam;