import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../config.js';

const PdfDownloadButton = () => {
  const handleDownload = async () => {
    try {
      const response = await fetch(`${API_URL}/download-pdf/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Arithmetic Sequance Note.pdf'; // Adjust the file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="flex items-center justify-center px-8 py-4 text-3xl font-bold text-white bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 rounded-lg shadow-lg hover:scale-110 hover:from-purple-600 hover:via-pink-600 hover:to-red-600 transition-transform duration-300 ease-in-out"
    >
      <FontAwesomeIcon icon={faDownload} className="mr-4 text-4xl" />
      Download Note
    </button>
  );
};

export default PdfDownloadButton;
