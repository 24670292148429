import React, { useState } from "react";
import axios from "axios"; // Import Axios
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faGraduationCap, faBook, faLanguage, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from '../config.js';

export const UserProfile = ({ onComplete }) => {
  const [profileData, setProfileData] = useState({
    name: "",
    class: "",
    syllabus: "",
    language: "",
    state: "",
  });

  console.log("profile data:", profileData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/subjectprofile/`, profileData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        console.log("Profile data submitted successfully:", response.data.subjects);
          // Pass both the response data (subjects) and the user's profile data
        onComplete({ ...profileData, subjects: response.data.subjects }); 
    } else {
        console.error("Failed to submit profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting profile data:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-black via-gray-600 to-white">
      <div className="bg-white p-12 rounded-3xl shadow-2xl max-w-lg w-full text-center  transform transition-all duration-500 hover:scale-105 hover:shadow-3xl">
        <h1 className="text-4xl font-extrabold mb-8 text-gray-700 animate-fade-in">Complete Your Profile</h1>
        <form onSubmit={handleSubmit} className="space-y-8">

          {/* Name Input */}
          <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faUser} className="text-gray-500 mr-4 transition-colors duration-300 animate-pulse" />
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={profileData.name}
              onChange={handleChange}
              className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
            />
          </div>

          {/* Class Input */}
          <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faGraduationCap} className="text-gray-500 mr-4 transition-colors duration-300 animate-pulse" />
            <input
              type="text"
              name="class"
              placeholder="Class"
              value={profileData.class}
              onChange={handleChange}
              className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
            />
          </div>

          {/* Syllabus Select */}
          <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faBook} className="text-gray-500 mr-4 transition-colors duration-300 animate-pulse" />
            <select
              name="syllabus"
              value={profileData.syllabus}
              onChange={handleChange}
              className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
            >
              <option value="" disabled className="text-gray-500">
                Select Syllabus
              </option>
              <option value="SCERT">SCERT</option>
              <option value="NCERT">NCERT</option>
              <option value="CBSE">CBSE</option>
            </select>
          </div>

          {/* Language Select */}
          <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faLanguage} className="text-gray-500 mr-4 transition-colors duration-300 animate-pulse" />
            <select
              name="language"
              value={profileData.language}
              onChange={handleChange}
              className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
            >
              <option value="" disabled className="text-gray-500">
                Select Language
              </option>
              <option value="English">English</option>
              <option value="Malayalam">Malayalam</option>
            </select>
          </div>

          {/* State Input */}
          <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-500 mr-4 transition-colors duration-300 animate-pulse" />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={profileData.state}
              onChange={handleChange}
              className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-gray-900 text-white py-4 rounded-lg font-bold text-lg uppercase hover:bg-gray-700 transform hover:scale-105 transition-transform duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
